/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.error {
    color: #dc3545;
    font-weight: 500;
}

.banner-height {
    height: 657px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-30 {
    margin-bottom: 30px;
}

.tdCenter {
    text-align: center;
}

.thNone {
    display: none;
}

.addButton {
    float: right;
    margin-bottom: 8px;
    padding: 11px 20px !important;
    min-width: 110px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 5px;
    background: #115adf;
    display: inline-block;
    border: none !important;
    color: white !important;
}

button.mt-13 {
    margin-top: 13px !important;
}

button.primary-btn {
    padding: 11px 20px !important;
    min-width: 110px !important;
    font-weight: 600 !important;
    border: none !important;
}

.container1 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.primary-btn1 {
    padding: 11px 20px !important;
    min-width: 110px !important;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 5px;
    background: #115adf;
    display: inline-block;
    border: none !important;
    color: white !important;
}

.floatRight {
    float: right !important;
}

/*New css for style*/
.left-0 {
    left: 0 !important;
}
.width-40 {
    width: 60% !important;
}
.opacity-1 {
    opacity: 1;
}
a.primary-btn {
    color: white !important;
}

.color-white {
    color: white !important;
}

.mr-20 {
    margin-right: 20px;
}
.btn-pd-mt {
    padding: 0.4rem !important;
    margin-top: 8px !important;
}

.btn-pd {
    padding: 0.4rem !important;
}

.btn-fr {
    margin-bottom: 5px;
    float: right;
}

.customize-btn {
    padding: 7px 14px !important;
    border-radius: 4px !important;
}

button.btn-primary {
    margin-top: 0px !important;
    border-radius: unset !important;
}

.tblexpand {
    width: 1%;
    text-align: center;
    cursor: pointer;
}

.kt-portlet {
    /* background: transparent; */
}

.form-body-wrapper {
    background: transparent;
    padding: 0;
}

.kt-portlet .kt-portlet__body .custom-family {
    border-top: 20px solid lightgrey;
    margin-top: 20px;
}

.kt-portlet .kt-portlet__body .health-wrapper {
    background: #fff;
    box-shadow: 0 4px 12px gainsboro;
    border-radius: 10px;
    padding: 16px;
}

.kt-portlet .kt-portlet__body .family-wrapper,
.kt-portlet .kt-portlet__body .female-wrapper,
.kt-portlet .kt-portlet__body .allergies-wrapper,
.kt-portlet .kt-portlet__body .healthing-wrapper,
.kt-portlet .kt-portlet__body .photographic-wrapper {
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 12px gainsboro;
    border-radius: 10px;
    padding: 16px;
}
.label-wrapper {
    padding: 12px 6px;
    background: #f1f0f0;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}
.label-wrapper label {
    padding: 0;
}

.preview-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
}

.preview-popup-modal {
    width: calc(100% - 120px);
    height: calc(100vh - 140px);
}
.preview-popup-header {
    background-color: #31373c;
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*   
table {
    border: 1px solid #555;
    border-width: 0 0 1px 1px;
}
table td {
    border: 1px solid #555;
    border-width: 1px 1px 0 0;
}

*/
table td {
    text-overflow: ellipsis;

    white-space: nowrap;
}

.scrollable-table {
    overflow-x: auto;
}

table {
    border-collapse: collapse;
}
.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
}

.first-col {
    /* width: 100px; */
    /* min-width: 100px; */
    /* max-width: 100px; */
    left: -1px;
}
