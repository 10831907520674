

::-moz-selection {
  /* Code for Firefox */
  background-color: #000;
  color: #fff;
}

::selection {
  background-color: #000;
  color: #fff;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #777777;
  font-weight: 300;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  font-weight: 300;
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
body {
  color: #080809;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.625em;
  position: relative;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

select {
  display: block;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}

.card-panel {
  margin: 0;
  padding: 60px;
}

/**
 *  Typography
 *
 **/
.btn i, .btn-large i, .btn-floating i, .btn-large i, .btn-flat i {
  font-size: 1em;
  line-height: inherit;
}

.gray-bg {
  background: #f9f9ff;
}

h1, h2, h3,
h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  color: #222222;
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}

.h1, .h2, .h3,
.h4, .h5, .h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #222222;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 22px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
}

td, th {
  border-radius: 0px;
}
 */
.clear::before, .clear::after {
  content: " ";
  display: table;
}
.clear::after {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.section-gap-full {
  padding: 70px 0;
}

.section-gap-half {
  padding: 30px 0;
}

.pb-30 {
  padding-bottom: 30px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.display-table {
  display: table;
}

.light {
  color: #fff;
}

.dark {
  color: #222;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container.fullwidth {
  width: 100%;
}
.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

.padding-top-120 {
  padding-top: 120px;
}

.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.scroll-top {
  width: 40px;
  height: 40px;
  line-height: 44px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: none;
  background: #115adf;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.section-title {
  margin-bottom: 75px;
}

.gradient-bg, .page-top-banner .overlay-bg, .banner-section .overlay-bg, .features-wrap .single-feature .overlay-bg {
  background: -moz-linear-gradient(0deg, #49b4ed 0%, #115adf 100%);
  background: -webkit-linear-gradient(0deg, #49b4ed 0%, #115adf 100%);
  background: -ms-linear-gradient(0deg, #49b4ed 0%, #115adf 100%);
}

.offwhite-bg {
  background-color: #eee;
}

.primary-btn {
  text-align: center;
  border-radius: 5px;
  background: #115adf;
  padding: 15px 40px;
  font-weight: 400;
  min-width: 160px;
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.primary-btn:hover {
  color: #fff;
  -webkit-box-shadow: 0px 30px 90px rgba(105, 28, 255, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(105, 28, 255, 0.14) !important;
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
}

.page-top-banner {
  /*background: url(./img/about-header.jpg);*/
  background-size: cover;
}
.page-top-banner .overlay-bg {
  opacity: .8;
}
.page-top-banner h1 {
  color: #000;
  font-size: 55px;
  margin-bottom: 10px;
}
.page-top-banner h4 {
  color: #000;
  font-weight: 400;
}

/* =================================== */
/*  Preloader Styles 
/* =================================== */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #115adf;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #49b4ed;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  -webkit-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    /*background: #222;*/
    background: transparent;
    z-index: 1000;
    -webkit-transform: translateX(0);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0);
    /* IE 9 */
    transform: translateX(0);
    /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);
  /* IE 9 */
  transform: translateX(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);
  /* IE 9 */
  transform: translateX(100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);
  /* IE 9 */
  transform: translateY(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  -o-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

/* =================================== */
/*  Banner Styles 
/* =================================== */
.header-area {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
}

.dope-nav-container {
  background-color: transparent;
  padding: 10px 0px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
  -o-transition: 0.25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
  transition: 0.25s cubic-bezier(0.43, 0.49, 0.51, 0.68);
}

.dope-nav-container.dope-sticky {
  background-color: #192a56;
  padding: 0px;
}

.dope-navbar {
  padding: 0.5em 5em;
}

.nav-brand {
  max-width: 120px;
}

.dope-btn {
  background-color: #4a7aec;
}

.dopenav ul li.megamenu-item > a::after,
.dopenav ul li.has-down > a::after {
  color: #fff;
}

.header-area {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
}

.dark.dope-nav-container {
  background-color: rgba(25, 42, 86, 0.3);
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.dope-nav-container.dope-sticky {
  background-color: #fffffff2;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-shadow: 0px 9px 40px -13px #115adf85;
  -moz-box-shadow: 0px 9px 40px -13px #115adf85;
  box-shadow: 0px 9px 40px -13px #115adf85;
}

.dope-navbar {
  padding: 0.5em 5em;
}

.nav-brand {
  max-width: 200px;
}
.nav-brand img {
    width: 100%;
}
.dope-btn {
  background-color: #4a7aec;
}

.dopenav ul li.megamenu-item > a::after,
.dopenav ul li.has-down > a::after {
  color: #fff;
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-transparent {
  background-color: transparent !important;
}

.dope-nav-container {
  position: relative;
  z-index: 100;
  /* sticky */
}

.dope-nav-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.dope-nav-container a {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #00A1E2;
  display: inline-block;
}

.dope-nav-container.dope-sticky {
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.dope-nav-container.box-skins .dopenav > ul > li {
  background-color: #1abc9c;
}

.dope-nav-container.box-skins .dopenav > ul > li > a {
  color: #fff;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dope-navbar {
  width: 100%;
  height: 70px;
  padding: 0.5em 2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.dope-navbar .nav-brand {
  font-size: 26px;
  font-weight: 500;
  color: #565656;
  display: inline-block;
  margin-right: 30px;
}

.dope-navbar .nav-brand:focus,
.dope-navbar .nav-brand:hover {
  font-size: 26px;
  font-weight: 500;
}

.dopenav ul li {
  display: inline-block;
  clear: both;
  position: inherit;
  z-index: 10;
}

.dopenav ul li.cn-dropdown-item {
  position: relative;
  z-index: 10;
}

.dopenav ul li.cn-dropdown-item ul li {
  position: relative;
  z-index: 10;
}

.dopenav ul li ul li {
  display: block;
}

.dopenav ul li ul li a {
  padding: 0 20px;
}

    .dopenav ul li a {
        /*padding: 6px 20px;*/
        padding: 6px 0px 6px 20px;
        display: block;
        /* height: 35px; */
        font-size: 14px;
        line-height: 34px;
        min-width: 105px;
        margin: 0 5px;
    }

.dopenav ul li .megamenu li a {
  padding: 0 12px;
}

.dopenav ul li div.single-mega a {
  height: auto;
  line-height: 1;
}

/* Down Arrow Icon for Megamenu */
.icon-dope-nav-down-arrow {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dope-nav-down-arrow:before {
  content: "\f107";
}

.dopenav ul li.megamenu-item > a:after,
.dopenav ul li.has-down > a:after {
  font-family: 'FontAwesome';
  content: "\f107";
  font-size: 12px;
  color: #000000;
  padding-left: 5px;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.breakpoint-on .dopenav ul li.megamenu-item > a:after,
.breakpoint-on .dopenav ul li.has-down > a:after {
  color: #fff;
}

.dopenav ul li ul li.has-down > a::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 3;
}

/* button css */
.dope-btn {
  position: relative;
  z-index: 1;
  min-width: 120px;
  padding: 0 25px;
  line-height: 42px;
  height: 42px;
  background-color: #1abc9c;
  border-radius: 50px;
  color: #fff !important;
  margin-left: 30px;
  text-align: center;
}

.dope-btn:hover,
.dope-btn:focus {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Dropdown Toggle */
.dd-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 500;
  border-radius: 0;
  display: none;
}

/* Close Icon */
.dopecloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
  display: none;
}

.dopecloseIcon .cross-wrap {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

.dopecloseIcon .cross-wrap span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 6px;
  background: #fff;
}

.dopecloseIcon .cross-wrap span.top {
  top: 12px;
  left: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dopecloseIcon .cross-wrap span.bottom {
  bottom: 12px;
  left: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* navbarToggler */
.dope-navbar-toggler {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: none;
}

.dope-navbar-toggler .navbarToggler {
  display: inline-block;
  cursor: pointer;
}

.dope-navbar-toggler .navbarToggler span {
  position: relative;
  background-color: #222;
  border-radius: 3px;
  display: block;
  height: 2px;
  margin-top: 5px;
  padding: 0;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  width: 30px;
  cursor: pointer;
}

.dope-navbar-toggler .navbarToggler span:nth-of-type(1) {
  width: 20px !important;
}

.dope-navbar-toggler .navbarToggler span:nth-of-type(3) {
  width: 15px !important;
}

.dope-navbar-toggler .navbarToggler.active span:nth-of-type(1) {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  top: 8px;
  width: 30px !important;
}

.dope-navbar-toggler .navbarToggler.active span:nth-of-type(2) {
  opacity: 0;
}

.dope-navbar-toggler .navbarToggler.active span:nth-of-type(3) {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
  top: -6px;
  width: 30px !important;
}

.dopenav ul li .megamenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  z-index: 200;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.dopenav ul li .megamenu .single-mega.cn-col-5 {
  width: 20%;
  float: left;
  padding: 15px;
  border-right: 1px solid #f2f4f8;
}

.dopenav ul li .megamenu .single-mega.cn-col-5:last-of-type {
  border-right: none;
}

.dopenav ul li .megamenu .single-mega.cn-col-4 {
  width: 25%;
  float: left;
  padding: 15px;
  border-right: 1px solid #f2f4f8;
}

.dopenav ul li .megamenu .single-mega.cn-col-4:last-of-type {
  border-right: none;
}

.dopenav ul li .megamenu .single-mega.cn-col-3 {
  width: 33.3333334%;
  float: left;
  padding: 15px;
  border-right: 1px solid #f2f4f8;
}

.dopenav ul li .megamenu .single-mega.cn-col-3:last-of-type {
  border-right: none;
}

.dopenav ul li .megamenu .single-mega .title {
  font-size: 14px;
  border-bottom: 1px solid #f2f4f8;
  padding: 8px 12px;
}

.dopenav ul li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li a {
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
}

.dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:last-child a {
  border-bottom: none;
}
.top-rightside {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.header-icon i{
    color: #00A1E2;
    font-size: 20px;
    margin: 0 20px;
}

/* Desktop Area CSS */
/*.breakpoint-off .dopenav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}*/

.breakpoint-off .dopenav ul li .dropdown {
  width: 180px;
  position: absolute;
  background-color: #ffffff;
  top: 120%;
  left: 0;
  z-index: 100;
  height: auto;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
}

.breakpoint-off .dopenav ul li .dropdown li .dropdown {
  top: 10px;
  left: -180px;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
}

.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown {
  opacity: 0;
  visibility: hidden;
  top: 120%;
}

.breakpoint-off .dopenav ul li:hover .dropdown {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.breakpoint-off .dopenav ul li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown {
  opacity: 1;
  visibility: visible;
  top: -10px;
}

.breakpoint-off .dopenav ul li .megamenu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 120%;
  background-color: #ffffff;
  z-index: 200;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
}

.breakpoint-off .dopenav ul li.megamenu-item:hover .megamenu,
.breakpoint-off .dopenav ul li.megamenu-item:focus .megamenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

/* Mobile/Tablet Nav CSS */
.breakpoint-on .dopecloseIcon {
  display: block;
}

.breakpoint-on .dope-navbar-toggler {
  display: block;
}

.breakpoint-on .dope-navbar .dope-menu {
  background-color: #115adf;
  position: fixed;
  top: 0;
  left: -310px;
  z-index: 1000;
  width: 300px;
  height: 100%;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  padding: 0;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}

.breakpoint-on .dope-navbar .dope-menu.menu-on {
  left: 0;
}

.breakpoint-on .dopenav ul li {
  display: block;
  position: relative;
  clear: both;
  z-index: 10;
}

.breakpoint-on .dopenav ul li a {
  padding: 0 10px;
  height: 38px;
  line-height: 38px;
  color: #fff;
}

.breakpoint-on .dopenav ul li .dropdown {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.breakpoint-on .dopenav ul li .dropdown li .dropdown {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.breakpoint-on .dopenav ul li.megamenu-item {
  position: relative;
  z-index: 10;
}

.breakpoint-on .dd-trigger {
  height: 38px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.breakpoint-on .dopenav ul li .dropdown,
.breakpoint-on .dopenav ul li .megamenu {
  display: none;
}

.breakpoint-on .dopenav {
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
}

.breakpoint-on .dopenav ul li .megamenu {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.breakpoint-on .dopenav ul li .megamenu .single-mega.cn-col-5,
.breakpoint-on .dopenav ul li .megamenu .single-mega.cn-col-4,
.breakpoint-on .dopenav ul li .megamenu .single-mega.cn-col-3 {
  width: 100%;
  border-right: none;
  border-bottom: 1px solid #f2f4f8;
}

.breakpoint-on .dopenav > ul > li > a {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.breakpoint-on .dopenav ul li ul.dropdown li ul li {
  margin-left: 15px;
}

.breakpoint-on .dope-btn {
  width: calc(100% - 30px);
  margin: 30px 15px;
}

.breakpoint-on .dopenav ul li.megamenu-item > a::after,
.breakpoint-on .dopenav ul li.has-down > a::after {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 5;
}

.breakpoint-on .dopenav ul li.has-down.active > a:after,
.breakpoint-on .dopenav ul li.megamenu-item.active > a:after {
  color: #fff;
}

/* Right Side Menu Open CSS */
.right.breakpoint-on .dope-navbar .dope-menu {
  left: auto;
  right: -310px;
}

.right.breakpoint-on .dope-navbar .dope-menu.menu-on {
  left: auto;
  right: 0;
}

/* Dropdown RTL CSS */
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown {
  left: auto;
  right: 0;
}

.dropdown-rtl .dopenav ul li a,
.dropdown-rtl .dopenav ul li .megamenu .single-mega .title {
  text-align: right;
}

.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown {
  right: 180px;
  left: auto;
  opacity: 0;
  visibility: hidden;
  top: 10px;
}

.dropdown-rtl.breakpoint-off .dopenav ul li:hover .dropdown {
  opacity: 1;
  visibility: visible;
  top: 90%;
}

.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown,
.dropdown-rtl.breakpoint-off .dopenav ul li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li .dropdown li:hover .dropdown {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.dropdown-rtl.breakpoint-on .dopenav ul li ul.dropdown li ul li {
  margin-left: 0;
  margin-right: 15px;
}

.event-header .dopenav #nav li a {
  color: #fff;
}
.event-header .dopenav ul li.megamenu-item > a:after,
.event-header .dopenav ul li.has-down > a:after {
  color: #fff;
}
.event-header .dropdown li > a:after {
  color: #222 !important;
}
.event-header .dropdown li a,
.event-header .single-mega li a {
  color: #222 !important;
}
.event-header .dope-sticky .dopenav #nav li a,
.event-header .dope-sticky .dopenav ul li.megamenu-item > a:after,
.event-header .dope-sticky .dopenav ul li.has-down > a:after {
  color: #222;
}
.event-header .dope-navbar-toggler .navbarToggler span {
  background-color: #fff !important;
}
.event-header .dope-sticky .dope-navbar-toggler .navbarToggler span {
  background-color: #222 !important;
}

/* =================================== */
/*  Banner Styles 
/* =================================== */
.banner-section {
  background-color: #49b4ed;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23df1cbd' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23c61dcd' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23aa20de' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%238624ee' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%235029ff' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%235029ff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%235029ff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%235029ff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235029ff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%235029ff' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  min-height: 950px;
}
.banner-section .overlay-bg {
  opacity: .6;
}
.banner-section .banner-left h1 {
  font-size: 55px;
}
.banner-section .banner-left h1,
.banner-section .banner-left p {
  color: #fff;
}
.banner-section .banner-left h4 {
  font-weight: 300;
  margin: 20px 0px;
}
.banner-section .banner-right {
  background: url(./img/screen-bg.png) center no-repeat;
  background-size: contain;
}
.banner-section .banner-right img {
  -webkit-animation: jump 5s linear 0s infinite normal forwards;
  animation: jump 5s linear 0s infinite normal forwards;
}
@keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  41% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  80% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  41% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  80% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.banner-section .wave svg {
  position: absolute;
  width: 100%;
  bottom: -1px;
  fill: #fff;
}
.banner-section .wave svg path:nth-child(1) {
  opacity: 0.15;
}
.banner-section .wave svg path:nth-child(2) {
  opacity: 0.3;
}

.banner-section2 {
    background: url(./img/home-banner8-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right;
    height: 100vh;
}
.banner-section2 .banner-left h1 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
}
.banner-section2 .banner-left h1 span {
  display: block;
  padding-top: 20px;
  font-weight: 600;
  font-size: 48px;
  line-height: 1;
    color: #00A1E2;
}
.banner-section2 .banner-left p {
  margin: 20px 0px;
  max-width: 450px;
}

.banner-section3 {
  /*background: url(./img/banner3.jpg);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.banner-section3 .overlay-bg {
  background: url(./img/banner-overlay.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-section3 .banner-left h1 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
}
.banner-section3 .banner-left h1 span {
  display: block;
  padding-top: 20px;
  font-weight: 600;
  font-size: 60px;
}
.banner-section3 .banner-left p {
  margin: 20px 0px;
  max-width: 450px;
}

/* =================================== */
/*  Features Styles 
/* =================================== */
.featured-section .row {
  padding-bottom: 75px;
  border-bottom: 1px solid #eee;
}
.featured-section .single-logo {
  text-align: center;
}
.featured-section .single-logo img {
  text-align: center;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: .5;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.featured-section .single-logo img:hover {
  -ms-transform: scale(0.9, 0.9);
  -webkit-transform: scale(0.9, 0.9);
  transform: scale(0.9, 0.9);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.feature-section {
    background-size: cover;
    background: #019de826;
}
.feature-section .header-left h1 {
  padding-bottom: 30px;
  font-size: 50px;
  text-transform: capitalize;
}
.feature-section .feature-wrap {
  padding-top: 60px;
}

.features-wrap .single-feature {
  background: #fff;
  border-radius: 5px;
  padding: 35px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 100%;
}
.icon-dr img {
    width: 60px;
    margin-bottom: 20px;
}

.icon-dr {
    position: relative;
    z-index: 1;
}
.features-wrap .single-feature span {
  font-size: 35px;
  color: #115adf;
  padding-bottom: 25px;
  display: inline-block;
}
.features-wrap .single-feature h3 {
  margin-bottom: 15px;
  font-weight: 400;
}
.features-wrap .single-feature h5 {
  margin-bottom: 10px;
}
.features-wrap .single-feature p {
  margin-bottom: 0px;
  line-height: 1.5em;
}
.features-wrap .single-feature:hover {
  -webkit-box-shadow: 0px 30px 90px rgba(105, 28, 255, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(105, 28, 255, 0.14) !important;
}
.features-wrap .single-feature:hover span,
.features-wrap .single-feature:hover p,
.features-wrap .single-feature:hover h3,
.features-wrap .single-feature:hover h5 {
  color: #fff;
}
.features-wrap .single-feature .overlay-bg {
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.features-wrap .single-feature:hover {
  background: transparent;
}
.features-wrap .single-feature:hover .overlay-bg {
  opacity: 1;
}
.features-wrap .single-feature:hover span,
.features-wrap .single-feature:hover h3,
.features-wrap .single-feature:hover h5,
.features-wrap .single-feature:hover p {
  position: relative;
}

.explore-section {
  /*background: url(./img/explore.jpg) right top no-repeat;*/
  background-size: cover;
  color: #fff;
}
.explore-section h1,
.explore-section h3 {
  color: #fff;
}
.explore-section h3 {
  font-weight: 300;
}
.explore-section h1 {
  font-size: 45px;
  padding-top: 10px;
  padding-bottom: 30px;
}
.explore-section .overlay-bg {
  background: -webkit-gradient(linear, left top, right top, from(#000), to(rgba(235, 143, 169, 0)));
  background: -webkit-linear-gradient(left, #000, rgba(235, 143, 169, 0));
  background: -o-linear-gradient(left, #000, rgba(235, 143, 169, 0));
  background: linear-gradient(to right, #000, rgba(235, 143, 169, 0));
}
.explore-section .counter-wrap {
  margin-top: 30px;
}
.explore-section .single-counter {
  width: calc(100%/3);
  border-right: 1px solid #eeeeee5c;
  text-align: center;
}
.explore-section .single-counter:last-child {
  border-right: none;
}
.explore-section .single-counter p {
  margin-bottom: 0px;
}
.explore-section .single-counter h2 {
  font-weight: 300;
  font-size: 50px;
  color: #fff;
}

.service-section {
  padding-bottom: 120px;
}
.service-section .single-service {
  position: relative;
  background-color: #f5f5f56b;
  padding: 40px 30px;
  -webkit-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
  -moz-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
  box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.service-section .single-service i {
  font-size: 35px;
  color: #222;
}
.service-section .single-service h4 {
  margin: 10px 0px;
}
.service-section .single-service p {
  margin-bottom: 0px;
}
.service-section .single-service:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
  -moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
  box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
}
.service-section .owl-dots {
  text-align: center;
  margin-top: 30px;
}
.service-section .owl-dots .owl-dot {
  width: 14px;
  height: 14px;
  background: #eee;
  display: inline-block;
  margin: 0px 5px;
}
.service-section .owl-dots .owl-dot.active {
  background: #115adf;
}
.service-section .owl-dots .owl-dot:focus {
  outline: none;
}

.skill-section {
  background: #eee;
}
.skill-section .skill-left p {
  margin-top: 10px;
}
.skill-section .skill-left .prog_container {
  margin-bottom: 25px;
  width: 90%;
}
.skill-section .skill-left .progress {
  background-color: #f9f9f9 !important;
}
.skill-section .skill-left .prog_container .prog_text {
  font-size: 13px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  font-weight: 700;
}
.skill-section .skill-left .prog_container .prog_text .precent {
  position: absolute;
  right: 0;
}
.skill-section .skill-left .prog_container .progress {
  height: 3px;
  border-radius: 4px;
}
.skill-section .skill-left .prog_container .progress .progress-bar {
  background-color: #115adf;
  height: 100%;
}
.skill-section .skill-left .prog_container .progress.md {
  height: 10px;
}
.skill-section .skill-left .prog_container .progress.lg {
  height: 20px;
}
@media all and (max-width: 767px) {
  .skill-section .skill-left .prog_container .prog_text .precent {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
  }
}
.skill-section .skill-right img {
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 7px 20px -10px #000;
  -moz-box-shadow: 0 7px 20px -10px #000;
  box-shadow: 0 7px 20px -10px #000;
}

/* =================================== */
/*  About Styles 
/* =================================== */
.about-section .overlay-bg1 {
  /*background: url(./img/bg2.png);*/
  background-size: cover;
  background-position: center;
}
.about-section .overlay-bg2 {
  /*background: url(./img/bg.png);*/
  background-size: cover;
  background-position: center;
}
.about-section .about-left img {
  width: 100%;
}
.about-section .about-right h3 {
  font-weight: 300;
}
.about-section .about-right h1 {
  margin-top: 20px;
  margin-bottom: 30px;
  max-width: 480px;
}
.about-section .about-right .icon {
  margin-right: 30px;
}
.about-section .about-right .icon span {
  font-size: 40px;
  color: #115adf;
}
.about-section .about-right .details p {
  max-width: 300px;
  margin-top: 15px;
}
.about-section .about-right .details h4 {
  font-weight: 400;
}
.about-section .about-right ul li {
  margin-bottom: 20px !important;
}
.about-section .about-right ul li:last-child {
  margin-bottom: 0px !important;
}

.floating-shapes span {
  display: block;
  position: absolute;
}
.floating-shapes span img {
  display: block;
  max-width: 100%;
  height: auto;
}
.floating-shapes span:nth-child(1) {
  left: 4.8%;
  top: 4.5%;
}
.floating-shapes span:nth-child(2) {
  left: 9.6%;
  top: 27.2%;
}
.floating-shapes span:nth-child(3) {
  left: 8.8%;
  bottom: 16%;
}
.floating-shapes span:nth-child(4) {
  left: 25%;
  bottom: 21%;
}
.floating-shapes span:nth-child(5) {
  left: 35%;
  top: 6.6%;
}
.floating-shapes span:nth-child(6) {
  left: 43%;
  bottom: 30%;
}
.floating-shapes span:nth-child(7) {
  left: 49%;
  top: 50%;
}
.floating-shapes span:nth-child(8) {
  right: 11.8%;
  top: 33%;
}
.floating-shapes span:nth-child(9) {
  right: 6%;
  top: 10%;
}
.floating-shapes span:nth-child(10) {
  right: 4.5%;
  bottom: 33%;
}

@media screen and (max-width: 767.98px) {
  .floating-shapes span {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    display: none;
  }
}
/* =================================== */
/*  Testimonial Styles 
/* =================================== */
.testimonial-section {
  padding-top: 120px;
}
.testimonial-section .testimonial-left p {
  margin-top: 10px;
  margin-bottom: 40px;
  max-width: 300px;
}
.testimonial-section .testimonial-right h2 {
  margin-bottom: 30px;
}
.testimonial-section .single-testimonial {
  border: 1px solid #eee;
  background: #eeeeee1f;
  border-radius: 5px;
  padding: 30px;
}
.testimonial-section .single-testimonial p {
  color: #222;
  font-weight: 400;
}
.testimonial-section .single-testimonial .img-wrap {
  margin-right: 20px;
}
.testimonial-section .single-testimonial .img-wrap img {
  border-radius: 30px;
}
.testimonial-section .single-testimonial .details p {
  margin-bottom: 0px;
  color: #8c8d9e;
}
.testimonial-section .testimonial-white-bg {
  background: #fff !important;
  border-color: #fff !important;
}
.testimonial-section .carusel-two .owl-dots .owl-dot {
  background: #fff;
}
.testimonial-section .owl-dots {
  text-align: left;
  margin-top: 30px;
}
.testimonial-section .owl-dots .owl-dot {
  border-radius: 20px;
  width: 14px;
  height: 14px;
  background: #eeeeee;
  display: inline-block;
  margin: 0px 5px;
}
.testimonial-section .owl-dots .owl-dot.active {
  background: #115adf;
}
.testimonial-section .owl-dots .owl-dot:focus {
  outline: none;
}

.screenshot-section {
  background: #eee;
}
.screenshot-section .owl-nav {
  text-align: center;
  margin-top: 40px;
}
.screenshot-section .owl-nav .owl-next, .screenshot-section .owl-nav .owl-prev {
  font-size: 30px !important;
}
.screenshot-section .owl-nav .owl-next:focus, .screenshot-section .owl-nav .owl-prev:focus {
  outline: none;
}

.bg-color-blue {
  background-color: #2a7af3;
}

.screenshot_slider .owl-stage .owl-item .item img {
  -webkit-transition: 0.3s;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.screenshot_slider .owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

.screenshot_slider .owl-stage .owl-item.center .item img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.screenshot_slider .owl-item {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.screenshot_slider .owl-controls .owl-prev, .testimonial-caroussel .owl-controls .owl-prev,
.screenshot_slider .owl-controls .owl-next, .testimonial-caroussel .owl-controls .owl-next {
  -webkit-box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0);
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0);
  color: #fff;
  height: 40px;
  left: 0;
  line-height: 3;
  margin: 0 5px;
  position: absolute;
  text-align: center;
  bottom: -16%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 40px;
  z-index: 9;
}

.screenshot_slider .owl-controls .owl-prev:hover, .testimonial-caroussel .owl-prev:hover,
.screenshot_slider .owl-controls .owl-next:hover, .testimonial-caroussel .owl-next:hover {
  border-color: #fff;
  color: #ffffff;
}

.screenshot_slider .owl-controls .owl-prev span, .testimonial-caroussel .owl-controls .owl-prev span,
.screenshot_slider .owl-controls .owl-next span, .testimonial-caroussel .owl-controls .owl-next span {
  font-size: 28px;
}

.screenshot_slider .owl-controls .owl-next {
  left: auto;
  right: 45%;
  font-size: 30px;
}

.screenshot_slider .owl-controls .owl-prev {
  right: auto;
  left: 45%;
  font-size: 30px;
}

/* =================================== */
/*  Portfolio Styles 
/* =================================== */
.portfolio-section .single-portfolio {
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
}
.portfolio-section .single-portfolio img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.portfolio-section .single-portfolio .box-content {
  width: 100%;
  padding: 40px 0;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.portfolio-section .single-portfolio:hover .box-content {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.portfolio-section .single-portfolio:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.portfolio-section .single-portfolio .box-content .title {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 5px 0;
}
.portfolio-section .single-portfolio .box-content .post {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
}
.portfolio-section .primary-btn {
  margin-top: 20px;
}

.product-detials-section img {
  width: 100%;
}
.product-detials-section .details-right {
  padding-bottom: 20px;
}
.product-detials-section .details-right ul {
  margin-bottom: 20px;
}
.product-detials-section .details-right ul li {
  margin-bottom: 8px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.product-detials-section .details-right ul li span {
  font-weight: 900;
}
.product-detials-section .details-right p {
  color: #000;
  font-weight: 400;
}
.product-detials-section .details-right p:last-child {
  margin-bottom: 0px;
}

/* =================================== */
/*  Blog Styles 
/* =================================== */
.blog-section .blog-wrap {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.blog-section .overlay-bg {
  background: rgba(34, 34, 34, 0.2);
  z-index: 1;
}
.blog-section .single-blog {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-section .single-blog:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
  -moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
  box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
}
.blog-section .single-blog img {
  position: relative;
  width: 100%;
}
.blog-section .single-blog .blog-post-details {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 1;
  padding: 20px;
}
.blog-section .single-blog .blog-post-details h4 {
  font-weight: 400;
}
.blog-section .single-blog .blog-post-details ul {
  display: block;
  padding-bottom: 15px;
}
.blog-section .single-blog .blog-post-details ul li {
  display: inline-block;
  margin-right: 3px;
}
.blog-section .single-blog .blog-post-details a {
  font-weight: 400;
}
.blog-section .single-blog h4,
.blog-section .single-blog a {
  color: #fff;
}
.blog-section .primary-btn {
  margin-top: 40px;
}

.blog-lists-section {
  background: #eee;
}
.blog-lists-section .single-blog-post {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  margin-bottom: 30px;
}
.blog-lists-section .single-blog-post:hover .post-thumb img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-lists-section .single-blog-post .post-thumb {
  overflow: hidden;
}
.blog-lists-section .single-blog-post .post-thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-lists-section .single-blog-post .post-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-lists-section .single-blog-post .post-details {
  padding: 30px;
}
.blog-lists-section .single-blog-post .tags li {
  display: inline-block;
  margin-right: 10px;
}
.blog-lists-section .single-blog-post .tags li a {
  color: #115adf;
  font-weight: 600;
  font-size: 14px;
}
.blog-lists-section .single-blog-post h1 {
  margin: 10px 0px;
}
.blog-lists-section .single-blog-post .user-details img {
  width: 45px;
  border-radius: 50px;
}
.blog-lists-section .single-blog-post .user-details p {
  margin-bottom: 0px;
}
.blog-lists-section .single-blog-post .user-details .details {
  margin-left: 8px;
}
.blog-lists-section .pagination .page-item {
  margin: 5px;
}
.blog-lists-section .pagination .page-link,
.blog-lists-section .pagination i {
  color: #222;
  font-weight: 500;
}
.blog-lists-section .pagination .page-link {
  width: 35px;
  text-align: center;
  border-radius: 3px;
}
.blog-lists-section .pagination .page-link:hover {
  background: #115adf;
  color: #fff;
}
.blog-lists-section .pagination .page-link:hover i {
  color: #fff;
}
.blog-lists-section .pagination .active .page-link {
  background: #115adf;
  color: #fff;
  border-color: transparent;
}
.blog-lists-section .single-widget {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  -webkit-box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  margin-bottom: 30px;
}
.blog-lists-section .single-widget .widget-title {
  margin-bottom: 20px;
}
.blog-lists-section .search-widget .sidebar-form form input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #eee;
  padding: 0 10px;
  text-indent: 10px;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  width: 100%;
  height: 50px;
}
.blog-lists-section .search-widget .sidebar-form form input:focus {
  outline: none;
}
.blog-lists-section .search-widget .sidebar-form form button {
  background: #222 none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 50px;
}
.blog-lists-section .search-widget .sidebar-form form button:hover {
  cursor: pointer;
}
.blog-lists-section .search-widget .sidebar-form form button:focus {
  outline: none;
}
.blog-lists-section .recent-post-widget ul li {
  margin-bottom: 20px;
}
.blog-lists-section .recent-post-widget ul li .thumbs {
  width: 40%;
}
.blog-lists-section .recent-post-widget ul li .details {
  margin-left: 10px;
}
.blog-lists-section .recent-post-widget ul li .details p,
.blog-lists-section .recent-post-widget ul li .details h5 {
  font-size: 12px;
}
.blog-lists-section .recent-post-widget ul li .details p {
  margin-bottom: 0px;
  font-weight: 400;
}
.blog-lists-section .social-widget li {
  display: inline-block;
  margin-right: 10px;
}
.blog-lists-section .social-widget li a {
  text-align: center;
  color: #fff;
  background: #222;
  padding: 10px;
  width: 42px;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-lists-section .social-widget li a:hover {
  background: #115adf;
}
.blog-lists-section .archive-widget ul li {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.blog-lists-section .archive-widget ul li:last-child {
  border-bottom: none;
}
.blog-lists-section .archive-widget ul li a {
  font-weight: 500;
  color: #222;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-lists-section .archive-widget ul li a:hover {
  color: #115adf;
}
.blog-lists-section .banner-widget {
  padding: 0px;
}
.blog-lists-section .tags-widget ul li {
  display: inline-block;
}
.blog-lists-section .tags-widget ul li a {
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  background: #000000;
  color: #fff;
  display: inline-block;
  padding: 8px 18px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-lists-section .tags-widget ul li a:hover {
  background: #115adf;
}

.blog-details {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  margin-bottom: 30px;
}
.blog-details:hover .post-thumb img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-details h1 {
  margin: 10px 0px;
}
.blog-details .post-thumb {
  overflow: hidden;
}
.blog-details .post-thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-details .post-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog-details .tags li {
  display: inline-block;
  margin-right: 10px;
}
.blog-details .tags li a {
  color: #115adf;
  font-weight: 600;
  font-size: 14px;
}
.blog-details .post-details {
  padding: 30px;
}
.blog-details .user-details img {
  width: 45px;
  border-radius: 50px;
}
.blog-details .user-details p {
  margin-bottom: 0px;
}
.blog-details .user-details .details {
  margin-left: 8px;
}
.blog-details .blockquote {
  border-left: 5px solid #115adf;
  font-size: 14px;
  margin-bottom: 35px;
  margin-left: 20px;
  margin-top: 35px;
  padding-left: 15px;
}

.comment-wrap {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  padding: 30px;
}
.comment-wrap h3 {
  margin-bottom: 30px;
}
.comment-wrap .media {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.comment-wrap .media:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}
.comment-wrap .media img {
  width: 90px;
  border-radius: 50px;
}
.comment-wrap .replay-comment {
  border-bottom: none;
  border-top: 1px solid #eee;
  padding: 20px;
  margin-bottom: 0px !important;
}
.comment-wrap .comments {
  margin-bottom: 30px;
}
.comment-wrap .comments:last-child {
  margin-bottom: 0px;
}
.comment-wrap .black-btn {
  min-width: 80px !important;
}
.comment-wrap .media-body h5 {
  margin-bottom: 10px;
}
.comment-wrap .comment-form h3 {
  margin-bottom: 30px;
}

.comment-form {
  margin-top: 30px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.14) !important;
  overflow: hidden;
  padding: 30px;
}
.comment-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #ced4da;
}
.comment-form .primary-btn {
  margin-top: 30px;
}
.comment-form h3 {
  margin-bottom: 30px;
}
.comment-form .form-col-wrap {
  margin-bottom: 30px;
}

/* =================================== */
/*  Team Styles 
/* =================================== */
.team-section {
  background-color: #eee;
}
.team-section .single-team {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.team-section .single-team img {
  width: 100%;
  position: relative;
}
.team-section .single-team:hover .team-content {
  opacity: 1;
}
.team-section .team-content {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: rgba(34, 34, 34, 0.8);
  padding: 20px 0px;
  color: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.team-section .team-content a,
.team-section .team-content h4 {
  color: #fff;
}
.team-section .team-content ul li {
  display: inline-block;
  margin: 5px;
}
.team-section .owl-dots {
  text-align: center;
  margin-top: 30px;
}
.team-section .owl-dots .owl-dot {
  width: 14px;
  height: 14px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
}
.team-section .owl-dots .owl-dot.active {
  background: #115adf;
}
.team-section .owl-dots .owl-dot:focus {
  outline: none;
}

/* =================================== */
/*  Accordian Styles 
/* =================================== */
.home-faq-section {
  padding-top: 120px;
}

.accordion > dt {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-shadow: 0 7px 20px -10px #000;
  -moz-box-shadow: 0 7px 20px -10px #000;
  box-shadow: 0 7px 20px -10px #000;
}

.accordion > dt > a {
  display: block;
  position: relative;
  color: #222222;
  text-decoration: none;
  padding: 14px 20px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
}

.accordion > dt > a:hover {
  text-decoration: none;
  border-color: #ddd;
  background: #fbfbfb;
}

.accordion > dt > a.active {
  color: #fff;
  border-color: #115adf;
  background: #115adf;
  cursor: default;
}
.accordion > dt > a.active:after {
  color: #fff !important;
}

.accordion > dt > a:after {
  content: "\f107";
  width: 15px;
  height: 15px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 10px;
  font-family: 'FontAwesome';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #999;
  -webkit-font-smoothing: antialiased;
}

.accordion > dt > a.active:after,
.accordion > dt > a.active:hover:after {
  content: "\f106";
  color: #111;
}

.accordion > dt > a:hover:after {
  color: #444;
}

.accordion > dd {
  margin-bottom: 10px;
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 1.8;
  color: #777;
}

.accordion > dt > a,
.accordion > dt > a:after,
.toggle > dt > a,
.toggle > dt > a:after {
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

.accordion > dt > a.active {
  pointer-events: none !important;
  cursor: default !important;
}

/* =================================== */
/*  CTA Styles 
/* =================================== */
.cta-section {
  /*background: url(./img/banner2.jpg);*/
  background-attachment: fixed;
  background-size: cover;
}
.cta-section h1, .cta-section h4, .cta-section p {
  color: #fff;
}
.cta-section h4 {
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 30px;
}
.cta-section .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* =================================== */
/*  Price Styles 
/* =================================== */
.price-section .price-left h4 {
  font-size: 35px;
}
.price-section .price-left p {
  color: #222;
  padding: 10px 0px;
  max-width: 320px;
}
.price-section .price-right .main {
  margin: -30px 0;
  background: #115adf;
  color: #fff;
}
.price-section .price-right .main i, .price-section .price-right .main h4, .price-section .price-right .main h2 {
  color: #fff;
}
.price-section .price-right .main .bottom-wrap {
  border-color: #fff;
}
.price-section .single-price {
  width: 100%;
  border-radius: 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 auto;
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 25, 0.05);
  box-shadow: 5px 5px 30px rgba(0, 0, 25, 0.05);
  padding: 40px 30px;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.price-section .single-price i {
  position: relative;
  font-size: 40px;
  color: #115adf;
}
.price-section .single-price h4 {
  position: relative;
  margin: 10px 0;
}
.price-section .single-price .bottom-wrap {
  border-top: 1px solid #8c8d9e;
  margin-top: 20px;
  padding-top: 20px;
}
.price-section .single-price .bottom-wrap i {
  font-size: 10px;
}
.price-section .single-price .bottom-wrap ul li {
  margin-bottom: 10px;
}
.price-section .single-price .bottom-wrap ul li:last-child {
  margin-bottom: 0px;
}
.price-section .single-price .bottom-wrap .primary-btn-w {
  background: #fff;
  color: #222;
  margin-top: 30px;
}
.price-section .single-price .bottom-wrap .primary-btn {
  margin-top: 30px;
}

/* =================================== */
/*  Download Styles 
/* =================================== */
.download-section {
  background: url(./img/download-bg.png);
}
.download-section p {
  margin-top: 20px;
  margin-bottom: 0px;
}
.download-section .download-wrap {
  padding-top: 80px;
}
.download-section .dload-btn {
  text-align: right;
}
.download-section .primary-btn {
  margin-left: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.download-section .primary-btn svg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

/* =================================== */
/*  Clients Styles 
/* =================================== */
.clients-wrap {
  padding: 30px 0px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.single-client {
  text-align: center;
}

.contact-wrap {
  padding: 60px 0px;
}
.contact-wrap h3 {
  font-weight: 400;
}
.contact-wrap h3 i {
  margin-right: 10px;
}

/* =================================== */
/*  Stat Styles 
/* =================================== */
.stat-section {
  background: #115adf;
}
.stat-section h1, .stat-section h4 {
  color: #fff;
}
.stat-section .single-stat {
  color: #fff;
}
.stat-section .single-stat i {
  font-size: 35px;
}
.stat-section .single-stat h1 {
  margin: 20px 0px;
}

/* =================================== */
/*  Contact Styles 
/* =================================== */
.contact-section {
  background: url(./img/download-bg.png);
  border-bottom: 100px solid #222;
}
.contact-section .section-title {
  padding-top: 120px;
}

.form-row {
  background: #fff;
  padding: 30px 0px;
  border-radius: 3px;
  margin-bottom: -100px;
  box-shadow: 0px 0px 158px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 0px 158px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 0px 158px 0px rgba(0, 0, 0, 0.11);
}

.address-wrap .single-address-col {
  text-align: center;
  margin-bottom: 40px;
}
.address-wrap .single-address-col i {
  font-size: 50px;
  color: #115adf;
}
.address-wrap .single-address-col p {
  font-size: 16px;
  margin-top: 10px;
  color: #222;
}

.contact-form-wrap {
  padding: 50px;
}
.contact-form-wrap .form-col {
  margin-bottom: 30px;
}
.contact-form-wrap .form-control {
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  padding-left: 0px;
}
.contact-form-wrap .form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #222 !important;
}
.contact-form-wrap .primary-btn {
  vertical-align: center;
  margin-top: 30px;
  border: none;
}
.contact-form-wrap .primary-btn:focus {
  outline: none;
}

.form-row .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff;
}
.form-row .error {
  background: #ef5659;
}
.form-row .success {
  background: #115adf;
}

.contact-page-section {
  background: #019de826 !important;
}
.contact-page-section .single-address-col .div {
  padding: 30px 0px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 40px;
}

.home-contact {
  padding-bottom: 50px;
}

/* =================================== */
/*  Footer Styles 
/* =================================== */
.footer-section {
    background: #00A1E2;
}
.footer-section .footer-menu {
    text-align: right;
    margin-top: 40px;
}
.footer-section .footer-menu li {
  display: inline-block;
  padding-left: 12px;
}
.footer-section .footer-menu li a {
  font-weight: 400;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-section .footer-menu li a:hover {
  color: #115adf;
}
.footer-section .copyright-text {
  margin-top: 30px;
  text-align: left;
  color: #fff;
}
.footer-section .copyright-text i {
  color: #FF0000;
}
.footer-section .copyright-text a {
  color: #fff;
}
.footer-section #social {
  text-align: right;
  margin-bottom: 40px;
}
.footer-section #social li {
  display: inline-block;
}
.footer-section #social li a {
  color: #fff;
  text-align: center;
  display: block;
  margin-left: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-section #social li a i {
  font-size: 18px;
}
.footer-section #social li a:hover {
  color: #115adf;
  border-color: #115adf;
}
.footer-section .footer-cols h4 {
  color: #fff;
  margin-bottom: 20px;
}
.footer-section .footer-cols p {
  color: #fff;
}
.footer-section .footer-cols #social {
  text-align: left;
  margin-top: 20px;
}
.footer-section .footer-cols #social li:first-child a {
  margin-left: 0px;
}
.footer-section .footer-cols ul li {
  margin-bottom: 10px;
  color: #fff;
}
.footer-section .footer-cols ul li a {
  color: #fff;
  font-weight: 400;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-section .footer-cols ul li a:hover {
  color: #115adf;
}
#banner-section input {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #000;
    padding: 0 10px;
    text-indent: 10px;
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
}
.footer-left a img {
    max-width: 160px;
    margin-bottom: 20px;
}
a.primary-btn{
  border-color: #00A1E2;
    color: #00A1E2;
    padding: 10px 20px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid;
    background: transparent;
}
    a.primary-btn:hover {
        border-color: #00A1E2;
        color: #fff !important;
        padding: 10px 20px;
        border: 1px solid;
        display: inline-block;
        border-radius: 3px;
        font-weight: 500;
        border: 1px solid;
        background: #00A1E2 !important;
    }
section.search-sec {
    background: #019de826;
    padding: 5rem 0;
}
section.search-sec input {
    width: 100%;
    border: 0;
    padding: 15px;
}
a.know-more {
    margin-top: 10px;
    display: block;
    font-weight: 600;
    
}

.features-wrap .single-feature:hover a.know-more {
    color: #fff;
    font-weight: 600;
}
section.resources {
    padding: 50px 0;
    border-bottom: 1px solid #eeeeee;
    background: #66C6EE;
}
.products{
  padding: 100px 0;
    border-top: 1px solid #eeeeee;
}
.r-inner-text {
    margin-top: 25px;
    color: #fff;
}

.r-inner-text h3 {font-size: 20px;color: #fff;margin-bottom: 10px;}

a.resources-btn {
    color: #fff;
    padding: 10px 20px;
    border: 1px solid;
    margin-top: 15px;
    display: inline-block;
    border-radius: 7px;
    font-weight: 500;
}
a.resources-btn:hover{
  background: #fff;
  color: #66C6EE;
}
.products-main {
    width: 70%;
    margin: 0 auto;
}
.resources .section-title h2{
  color: #fff !important;
}
.products-inner h2 {
    text-align: center;
    color: #000;
    width: 80%;
    background: #019de826;
    padding: 60px 30px;
    height: 160px;
    margin: 0 auto;
    /* font-size: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 3px 12px 2px #115adf69;
}
.logo-rounded {
    overflow: hidden;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}
.section-pad.bg-blue-light {
    padding: 45px 0;
    background: #F0F5FB;
}
.logo-rounded--less {
    border-radius: 0.5rem;
}
.logo-rounded {
    overflow: hidden;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}
.media .media-body {
    -ms-flex: 1;
    flex: 1;
    margin-left: 15px;
}
.logo-rounded {
    width: 160px;
}
.logo-max-h-270 {
    height: 207.5px;
}
.logo-rounded img {
    width: 100%;
}
.bg-gradient-1.section-pad {
    background: #CEEBF9;
    padding: 45px 0;
}
.btn-primary {
    border-color: #00A1E2;
    color: #00A1E2;
    padding: 10px 20px;
    border: 1px solid;
    margin-top: 15px;
    display: inline-block;
    border-radius: 7px;
    font-weight: 500;
    border: 1px solid;
    background: transparent;
}
.section-pad-less {
    padding: 50px 0;
}
.footer-section p {
    color: #fff;
    margin-bottom: 5px;
}

.footer-title {
    text-align: right;
    color: #fff !important;
}

.footer-title h2 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 25px;
}
.resources-2 .r-inner-text h2 {
    font-size: 21px;
    margin-bottom: 20px;
}
.resources-2 {
  padding: 60px 0;
  color: #222;
}
.resources-2 p{
  color: #222;
}
#kt_login {
    min-height: 100vh;
    padding: 0;
}
a.primary-btn {
    border-color: #00A1E2;
    color: #00A1E2 !important;
    padding: 10px 20px !important;
    border: 1px solid !important;
    display: inline-block;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid;
    background: transparent;
}
#banner-section .container {
    height: 100%;
}

#banner-section .row.align-items-center.fullscreen {
    height: 100%;
}
.logo.logo-sm-260.mb-2.ie-width-100 img {
    width: 100%;
}

.logo.logo-sm-260.mb-2.ie-width-100 {
    width: 100%;
}
a.primary-btn:hover {
    border-color: #00A1E2;
    color: #fff !important;
    padding: 10px 20px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid;
    background: #00A1E2 !important;
}

.page-title h1 {
  padding: 0 10px;
  font-size: 20px;
  line-height: 30px;
  color: #323232;
}

  .checkbox-alignment {
    display: flex;
    align-items: center;
  }

  .checkbox-alignment div input[type="checkbox"]{
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
  }

  .checkbox-alignment > div > span {
    font-size: 14px;
    line-height: 20px;
    display: block;
    padding: 15px 0 0 0;
    padding-left: 2.25rem;
  }



  .checkbox-alignment {
    display: flex;
    align-items: center;
  }

  .all-checkbox-alignment {
    padding: 0 10px;
  }