.card0 {
    /*margin-top: 35px;*/
}
.products-inner h2 {
    text-align: center;
    color: #000;
    width: 50%;
    background: #019de826;
    padding: 60px 30px;
    /* height: 160px; */
    margin: 0 auto;
    /* font-size: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 3px 12px 2px #115adf69;
    margin-bottom: 30px;
}
.card.card0.border-0 .col-lg-6:nth-child(1) {
    border-right: 1px solid #0000001f;
}
.middle-content{
  margin: 50px 0;
}
.dopenav ul li a {
    padding: 6px 10px;
    display: block;
    /* height: 35px; */
    font-size: 14px;
    line-height: 34px;
    min-width: auto;
    margin: 0 5px;
}
a.primary-btn{
    padding: 6px 20px !important;
    min-width: 110px !important;
}
.card2 {
    margin: 0px 40px
}
.header-area {
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
      box-shadow: 0px 9px 40px -13px #115adf85;
}
.logo {
    width: 200px;
    height: 100px;
    margin-top: 20px;
    margin-left: 35px
}

.image {
    width: 360px;
    height: 280px
}

.border-line {
    border-right: 1px solid #EEEEEE
}

.facebook {
    background-color: #3b5998;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer
}

.twitter {
    background-color: #1DA1F2;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer
}

.linkedin {
    background-color: #db3236;
    color: #fff;
    font-size: 18px;
    padding-top: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer
}

.line {
    height: 1px;
    width: 45%;
    background-color: #E0E0E0;
    margin-top: 10px
}

.or {
    width: 10%;
    font-weight: bold
}

.text-sm {
    font-size: 14px !important
}

::placeholder {
    color: #BDBDBD;
    opacity: 1;
    font-weight: 300
}

:-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 300
}

::-ms-input-placeholder {
    color: #BDBDBD;
    font-weight: 300
}

input,
textarea {
    padding: 10px 12px 10px 12px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
    letter-spacing: 1px
}

input:focus,
textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #304FFE;
    outline-width: 0
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

a {
    color: inherit;
    cursor: pointer
}

.btn-blue {
    background-color: #115adf;
    width: 150px;
    color: #fff;
    border-radius: 2px
}

.btn-blue:hover {
    background-color: #000;
    cursor: pointer
}

.bg-blue {
    color: #fff;
    background-color: #1A237E
}
.footer-section .footer-menu li a:hover {
    color: #fff;
}
a.login-inner-pg {
    text-align: center;
    color: #000;
    width: 85%;
    background: #019de826;
    padding: 30px 0px;
    margin: 0 auto;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 3px 12px 2px #115adf69;
    font-weight: 500;
}
a.login-inner-pg img {
    width: 100px;
    margin-right: 20px;
}
.login-selection-pg {
    min-height: 450px;
    align-items: center;
}
.login-inner-title {
    text-align: center;
    margin-bottom: 60px;
}

.login-inner-title h3 {
    font-size: 38px;
    color: #0056b3;
}
.tables {
    margin: 50px 0;
}
.profile-pg {
    margin-top: 50px;
}
.widgets-main {
    box-shadow: 0 2px 5px 5px rgba(0, 123, 255, 0.06);
    margin: 50px 0;
    padding: 25px;
}
.table-inner {
    border: 1px solid #8d8d8d66;
    margin: 25px 0;
    padding: 20px;
    border-radius: 5px;
}
h3.heading {
    margin-bottom: 30px;
    text-transform: uppercase;
}
.inner-sec {
    margin-bottom: 20px;
}
 .profile-image {
    text-align: center;
}
.main-details {
    padding-top: 50px;
    background: #fff;
    box-shadow: 0px 9px 40px -13px #115adf85;
    margin-top: 25px;
    padding-bottom: 25px;
}
.profile-image img{
     width: 8%;
}
.high-text {
    font-size: 18px;
    color: #115adf;
    font-weight: 600;
}
.low-text {
    margin-bottom: 25px;
    font-size: 17px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 3px;
}
#main-details-row {
    padding: 50px;
}
/*.table-inner {    
    box-shadow: 0 2px 13px 1px #115adf1f;
    margin: 50px 0;
    padding: 25px;
}
.bootstrap.snippet{
    box-shadow: 0 2px 13px 1px #115adf1f;
    margin: 50px 0;
    padding: 25px;
}*/
#intro {
    width: 100%;
    position: relative;
    /* background: url(../img/intro-bg.png) center bottom no-repeat; */
    background-size: cover;
    padding: 120px 0 120px 0;
}
.covid-sec {
    margin-bottom: 100px;
}

#intro h2 {
    color: #fff;
    font-size: 55px;
    margin-bottom: 0 !important;
}
#intro .section-title {
    margin-bottom: 0;
}
div#main-details-row {
    margin: 0;
    padding: 40px;
}
.timeline {
     position: relative;
}
 .timeline::before {
     content: '';
     width: 5px;
     height: 95%;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
}
 .timeline-item {
     width: 100%;
     margin-bottom: 70px;
}
 .timeline-item:nth-child(even) .timeline-content {
     float: right;
     /*padding: 40px 30px 10px 30px;*/
}
 /*.timeline-item:nth-child(even) .timeline-content .date {
     right: auto;
     left: 0;
}*/
 .timeline-item:nth-child(even) .timeline-content::after {
     content: '';
     position: absolute;
     border-style: solid;
     width: 0;
     height: 0;
     top: 30px;
     left: -15px;
     border-width: 10px 15px 10px 0;
     border-color: transparent #f5f5f5 transparent transparent;
}
 .timeline-item::after {
     content: '';
     display: block;
     clear: both;
}
 .timeline-content {
     position: relative;
     width: 45%;
     padding: 10px 30px;
     border-radius: 4px;
     background: #f5f5f5;
     box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
}
 .timeline-content::after {
     content: '';
     position: absolute;
     border-style: solid;
     width: 0;
     height: 0;
     top: 30px;
     right: -15px;
     border-width: 10px 0 10px 15px;
     border-color: transparent transparent transparent #f5f5f5;
}
 .timeline-img {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     position: absolute;
     left: 50%;
     margin-top: 25px;
     margin-left: -15px;
}
.timeline-img a {
     padding: 8px 20px;
     text-transform: uppercase;
     font-size: 14px;
     margin-bottom: 20px;
     margin-top: 10px;
     display: inline-block;
     border-radius: 2px;
     box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);
}
 a:hover, a:active, a:focus {
     text-decoration: none;
}
 .timeline-card {
     padding: 0 !important;
}

 .timeline-card a {
     margin-left: 20px;
}
 .timeline-item .timeline-img-header {
     /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url('https://picsum.photos/1000/800/?random') center center no-repeat; */
     background-size: cover;
}
 .timeline-img-header {
     height: 200px;
     position: relative;
     margin-bottom: 20px;
}
 .timeline-img-header h2 {
     position: absolute;
     bottom: 5px;
     left: 20px;
}
 blockquote {
     margin-top: 30px;
     padding: 0 20px;
}
 .date {
     display: inline-block;
     padding: 10px;
     position: absolute;
     top: 0;
     right: 0;
}
.timeline::before {
    content: '';
    background: #C5CAE9;
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.timeline-img {
    width: 30px;
    height: 30px;
    background: #115adf;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}

.inner-title {
    text-align: center;
    margin-bottom: 50px;
}
.timeline-card {
    padding: 10px 30px !important;
}
.dashboard-title-inner h3 {
    margin-bottom: 50px;
    text-align: left;
    font-size: 30px;
    margin-top: 10px;
    position: relative;
}
.span
{
    font-Size: 15px;
    font-family: Poppins, serif;
    color: rgb(11, 83, 148);
}

/*.inner-title h1 {
    color: #007bff;
}*/
.dashboard-title-inner h3:after {content: '';display: block;position: absolute;width: 7%;background: #007bff;bottom: -10px;height: 3px;}
 @media screen and (max-width: 768px) {
     .timeline::before {
         left: 50px;
    }
     .timeline .timeline-img {
         left: 50px;
    }
     .timeline .timeline-content {
         max-width: 100%;
         width: auto;
         margin-left: 70px;
    }
     .timeline .timeline-item:nth-child(even) .timeline-content {
         float: none;
    }
     .timeline .timeline-item:nth-child(odd) .timeline-content::after {
         content: '';
         position: absolute;
         border-style: solid;
         width: 0;
         height: 0;
         top: 30px;
         left: -15px;
         border-width: 10px 15px 10px 0;
         border-color: transparent #f5f5f5 transparent transparent;
    }
}

@media screen and (max-width: 991px) {
    .logo {
        margin-left: 0px
    }

    .image {
        width: 300px;
        height: 220px
    }

    .border-line {
        border-right: none
    }

    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px
    }
    
}
.uk-article-title {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0;
  margin-right: 30px;
}

.uk-article-title .tm-totop {
  margin-right: -30px;
}
.uk-article-title .tm-totop {
  margin-right: -30px;
}
.uk-float-right {
  float: right;
}
.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-container {
  box-sizing: content-box;
  /* 1 */
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
  font-size: 30px;
}
strong {
  font-weight: bolder;
  }

small {
  font-size: 80%; }
  h1, .uk-h1 {
  font-size: 3.6rem;
  line-height: 1.2;
}

h2, .uk-h2 {
  font-size: 2.6rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .uk-h5 {
  font-size: 18px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4;
}
p{
margin-top: 20px
}

.margintop{
    margin-top: 3%;
}
